import React from 'react';
import PropTypes from 'prop-types';

import SmallSwitch from 'src/components/form/SmallSwitch';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

import './SortSearchBar.scss';

const SortSearchBar = ({ sortedByCompany, setSortedByCompany, enableSearchArea, labels }) => (
  <div id="kl-sort-search-bar">
    <div className="kl-ssb-sort-container">
      <span
        className={`kl-ssb-bydate-label ${sortedByCompany ? ' kl-ssb-label-faded' : ''}`}
        onClick={() => setSortedByCompany(false)}
      >
        {labels.klipsoLeads.sortByDate}
      </span>

      <SmallSwitch
        className="kl-ssb-switch"
        checked={sortedByCompany}
        onChange={(event) => setSortedByCompany(event.target.checked)}
      />

      <span
        className={`kl-ssb-bycompany-label ${!sortedByCompany ? ' kl-ssb-label-faded' : ''}`}
        onClick={() => setSortedByCompany(true)}
      >
        {labels.klipsoLeads.sortByCompany}
      </span>
    </div>

    <Separator />

    <div className="kl-ssb-search-btn" onClick={enableSearchArea}>
      <GenericIcon st0Class="search-icon-st0" layout='i' className="search-icon" />
    </div>
  </div>
);

SortSearchBar.propTypes = {
  sortedByCompany: PropTypes.bool,
  setSortedByCompany: PropTypes.func,
  enableSearchArea: PropTypes.func,
  labels: PropTypes.object,
};

export default SortSearchBar;

const Separator = ({ sync }) => <span className="kl-ssb-separator" />;
