import React from 'react';
import PropTypes, { object } from 'prop-types';
import { ReactSVG } from 'react-svg';

import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { objToString } from 'src/core/util/JsTools';
import './GenericIcon.scss';

const GenericIcon = ({
  style,
  title,
  className,
  onClick,
  layout: Layout,
  href,
  key,
  children,
  onTouchMove,
  disabled,
  src,
  st0Style,
  st0Class,
  st1Style,
  st1Class,
  wrapperStyle,
  fill,
}) =>
  src ? (
    <ReactSVG
      src={getUrl(src, false)}
      afterInjection={(err, svg) => {
        if (err) {
          console.error('error in ReactSVG', err);
          return;
        }
        const paths = Array.from(svg.getElementsByTagName('path'));
        if (typeof fill === 'string') {
          paths.forEach((path) => path.setAttribute('fill', fill));
        } else if (Array.isArray(fill)) {
          paths.forEach((path, i) => {
            const color = fill[i] ? fill[i] : fill[0]; // if less colors than paths, use first color
            path.setAttribute('fill', color);
          });
        }
      }}
      beforeInjection={(svg) => {
        title && svg.setAttribute('title', title);
        style && svg.setAttribute('style', objToString(style));
        href && svg.setAttribute('href', href);
        key && svg.setAttribute('key', key);
        disabled && svg.setAttribute('disabled', disabled);
        (st0Style || st0Class) &&
          svg
            .querySelector('.st0')
            .setAttribute(
              st0Style ? 'style' : 'class',
              st0Style ? objToString(st0Style) : st0Class
            );
        (st1Style || st1Class) &&
          svg
            .querySelector('.st1')
            .setAttribute(
              st1Style ? 'style' : 'class',
              st1Style ? objToString(st1Style) : st1Class
            );
      }}
      style={Object.assign({ background: 'none', mask: 'none', WebkitMask: 'none' }, wrapperStyle)}
      onTouchMove={onTouchMove}
      onClick={onClick}
      wrapper={Layout}
      className={className}
      title={title}
      key={key}
      href={href}
      disabled={disabled}
    >
      {children}
    </ReactSVG>
  ) : (
    <Layout
      href={href}
      onClick={onClick}
      onTouchMove={onTouchMove}
      disabled={disabled}
      title={title}
      className={className}
      style={style}
      key={key}
    >
      {children}
    </Layout>
  );
GenericIcon.defaultProps = {
  className: null,
  onClick: () => {},
  style: null,
  title: null,
  layout: 'span',
  href: null,
  key: null,
  children: null,
  disabled: null,
  onTouchMove: () => {},
  src: null,
  st0Style: null,
  st1Style: null,
  st0Class: null,
  st1Class: null,
  wrapperStyle: {},
  fill: null,
};

GenericIcon.propTypes = {
  onClick: PropTypes.func,
  onTouchMove: PropTypes.func,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  title: PropTypes.string,
  style: PropTypes.object,
  layout: PropTypes.string,
  href: PropTypes.string,
  key: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabled: PropTypes.bool,
  src: PropTypes.string,
  st0Style: PropTypes.object,
  st1Style: PropTypes.object,
  st0Class: PropTypes.string,
  st1Class: PropTypes.string,
  wrapperStyle: PropTypes.object,
  fill: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default GenericIcon;
