module.exports = require("deepmerge")(require("./config.default.js"), {
  native: {
    name: {
      default: "SANTEXPO 2020_D"
    },
    id: "com.mobilespot.santexpo2020.dev",
    // iOS
    appleTeamId: "2BAP3P29V2",
    // iosAppStoreUrl: 'https://itunes.apple.com/app/id1484463502',
    // Android
    // 'androidapp-id': 'TODO', // unused?
    playStoreUrl: ""
  },
  web: {
    url: "santexpo-dev.mobile-spot.com"
  }
});