import React from 'react';
import PropTypes from 'prop-types';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

const DetailCountry = ({ country }) =>
  !country ? null : (
    <div>
      <div className="free-row">
        <div className="prop-img">
          <div className="prop-left">
            <div className="fiche-contact-icon">
              <GenericIcon src="icons/fiche/globe-solid.svg" className="globe-icon" />
            </div>
          </div>
          <div className="prop-right">
            <span>{country}</span>
          </div>
        </div>
      </div>
    </div>
  );

DetailCountry.propTypes = {
  country: PropTypes.string,
};

export default DetailCountry;
