import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Rate from 'rc-rate';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dialog from 'src/components/dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

import moment from 'moment';
import moment_fr from 'moment/locale/fr';
import moment_en from 'moment/locale/en-gb';

import Checkboxes from './Checkboxes';

import {
  isFirstStep,
  isLastStep,
  getPollStep,
  getPollList,
  getPollConfigById,
} from 'src/core/polls/PollManager';

import { ERRORS } from 'src/core/polls/PollService';

import { iosHackScrollTo0 } from 'src/core/util/browser';

import * as actions from 'src/store/actions';

import './Rate.scss';
import './PollDialog.scss';

export const COMPONENT_KEY = 'PollDialog';
export const CONTAINER_DOM_ID = 'poll-container';

let _containerStyle;
/**
 * The point is to create a new object only if value is different,
 * to avoid useless renders
 * @return {object}
 */
const getContainerStyle = () => {
  let maxHeight = document.documentElement.clientHeight * 0.75 + 'px';

  if (!_containerStyle || _containerStyle.maxHeight !== maxHeight) {
    _containerStyle = {
      maxHeight: maxHeight,
    };
  }
  return _containerStyle;
};

class PollDialog extends Component {
  state = {
    code: '',
    displayScrlCtrl: true,
  };

  constructor() {
    super();
    const self = this;
    this.nav = {
      error: self.renderErrorPage,
      list: self.renderListPage,
      form: self.renderPollCodeFormPage,
      formValid: self.renderPollCodeValidPage,
      question: self.renderQuestionPage,
      submit: self.renderSubmitPage,
      submitted: self.renderSubmittedPage,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      (nextProps.config && this.props.config !== nextProps.config) ||
      (this.props.config && nextProps.config && nextProps.config.q_id !== this.props.config.q_id)
    ) {
      if (nextProps.poll_id && nextProps.config && nextProps.config.q_id) {
        const step = getPollStep(nextProps.poll_id, nextProps.config.q_id);

        if (!step.data) {
          const {
            config: { q_type, q_choices },
          } = nextProps;
          /*const choices = q_type === 'choice' ? Object.keys(q_choices).map((key) => ({
                        ...q_choices[key],
                        key
                    })) : null*/
          // set default choice / mark / comment
          if (q_type === 'mark') {
            this.props.actions.setPollMark(0);
          } else if (q_type === 'choice') {
            // this.props.actions.setPollChoice(choices[0].key)
            this.props.actions.setPollChoice(null);
          } else if (q_type === 'multiple') {
            this.props.actions.setPollMultiple(null);
          } else {
            this.props.actions.setPollComment('');
          }
        }
      }
    }

    this.checkDisplayScrlCtrl(nextProps);
  }

  checkDisplayScrlCtrl(nextProps) {
    const { config } = nextProps;
    if (config && config.q_type && (config.q_type === 'choice' || config.q_type === 'multiple')) {
      setTimeout(() => {
        if (this.refs.scrollableEl) {
          const innerH = this.refs.scrollableEl.scrollHeight;
          const outterH = this.refs.scrollableEl.clientHeight;

          if (innerH > outterH) {
            this.setState({ displayScrlCtrl: true });
          } else {
            this.setState({ displayScrlCtrl: false });
          }
        }
      }, 30);
    } else {
      this.setState({ displayScrlCtrl: false });
    }
  }

  setCodeField = (el) => {
    this.codeField = el;
  };

  handleCodeChange = (event) => {
    this.setState({ code: event.target.value });
  };

  handleCodeSubmission = (e) => {
    const { poll_id } = this.props;
    if (e && e.preventDefault && typeof e.preventDefault === 'function') e.preventDefault();
    if (!this.codeField.value) {
      this.props.actions.showNotification({ message: this.props.labels.poll.fillTheFields });
      return;
    }
    this.props.actions.validatePollCode(poll_id, this.codeField.value);
  };

  renderListPage = () => {
    const { profile, actions, labels, lang } = this.props;
    const pollList = getPollList(profile);

    if (!pollList || pollList.length === 0) {
      return (
        <div className="generic-modal-content">
          <GenericIcon
            className="close-contributions-dialog times-icon"
            src="icons/control/times-solid.svg"
            onClick={actions.hidePollDialog}
          />
          <div className="title-font">{labels.poll.noPolls}</div>
        </div>
      );
    }

    return (
      <div id="poll-dialog" className="generic-modal-content">
        <GenericIcon
          className="close-contributions-dialog times-icon"
          src="icons/control/times-solid.svg"
          onClick={actions.hidePollDialog}
        />
        <div className="title-font">{labels.poll.listTile}</div>
        <div className="poll-body">
          <ul className="poll-list">
            {pollList.map((poll) => {
              const localState = poll.local_state;
              const localStateText = labels.poll[localState];

              return (
                <li
                  key={poll.poll_id}
                  onClick={() => {
                    actions.setPollId(poll.poll_id, true);
                    // this.getLocation()
                  }}
                  className="poll-item"
                >
                  <div className="poll-detail title-font">
                    <span className={`poll-topic ${poll.opened ? 'active' : ''}`}>
                      {poll.poll_title[lang]}
                    </span>
                    <span className={`poll-local-state ${localState}`}>{localStateText}</span>
                  </div>
                  <div className="poll-state-icon title-font">
                    <GenericIcon
                      className={` ${poll.poll_code || poll.poll_restricted ? 'lock-icon' : ''}`}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  };

  renderPollCodeFormPage = () => {
    const { error, poll_id, lang } = this.props;
    const poll = getPollConfigById(poll_id);
    const pollTitle =
      poll && poll.poll_title ? poll.poll_title[lang] : this.props.labels.poll.sessionTitle;
    return (
      <>
        <DialogTitle className="title-font modal-title">{pollTitle}</DialogTitle>
        <div className="generic-modal-content">
          <div className="poll-body">
            {error === ERRORS.CODE && (
              <div className="poll-text error-message text-bottom title-font">
                {this.props.labels.poll.sessionCodeError}
              </div>
            )}

            {poll && poll.poll_code_label && (
              <div className="poll-text text-bottom title-font">{poll.poll_code_label[lang]}</div>
            )}
            {!poll && (
              <div className="poll-text text-bottom title-font">
                {this.props.labels.poll.enterCode}
              </div>
            )}

            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.handleCodeSubmission();
              }}
            >
              <div className="poll-form-row">
                {/* <label htmlFor="login">{this.props.labels.login.loginLabel}</label> */}
                <input
                  className="generic-input"
                  ref={this.setCodeField}
                  value={this.state.code}
                  placeholder={this.props.labels.poll.code}
                  onChange={this.handleCodeChange}
                  onBlur={iosHackScrollTo0}
                  name="code"
                  type="text"
                />
              </div>
            </form>
          </div>

          <div className="poll-footer generic-btn-container content-font">
            <div className="generic-btn" onClick={this.props.actions.hidePollDialog}>
              {this.props.labels.common.cancel}
            </div>
            <div className="generic-btn cta-modal-btn" onClick={this.handleCodeSubmission}>
              {this.props.labels.common.ok}
            </div>
          </div>
        </div>
      </>
    );
  };

  renderErrorPage = () => {
    const { error, poll_open_date, poll_close_date, poll_topic, lang } = this.props;

    let moment_locale = moment_fr;
    if (lang === 'en') {
      moment_locale = moment_en;
    }

    moment.locale(lang, moment_locale);

    return (
      <>
        <DialogTitle className="title-font modal-title">{poll_topic[lang]}</DialogTitle>
        <div className="generic-modal-content">
          {error === ERRORS.OUTDATED_AFTER && (
            <div className="poll-body">
              <div className="poll-text error-message text-bottom title-font">
                {this.props.labels.poll.isClosed}
              </div>
              <div className="poll-text error-message bold title-font">
                {moment(poll_close_date, 'YYYY/MM/DD HH:mm').locale(lang).format('LLL')}
              </div>
            </div>
          )}

          {error === ERRORS.OUTDATED_BEFORE && (
            <div className="poll-body">
              <div className="poll-text error-message text-bottom title-font">
                {this.props.labels.poll.willOpen}
              </div>
              <div className="poll-text error-message bold title-font">
                {moment(poll_open_date, 'YYYY/MM/DD HH:mm').locale(lang).format('LLL')}
              </div>
            </div>
          )}

          <div className="content-font">
            <div className="generic-btn-container">
              <div
                className="generic-btn cta-modal-btn"
                onClick={this.props.actions.hidePollDialog}
              >
                {this.props.labels.common.close}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  renderPollCodeValidPage = () => {
    const { poll_close_date, poll_intro_title, lang } = this.props;
    return (
      <>
        <DialogTitle className="title-font modal-title">{poll_intro_title[lang]}</DialogTitle>
        <div className="generic-modal-content">
          <div className="poll-body">
            <div className="poll-text text-bottom">{this.props.labels.poll.willClose}</div>
            <div className="poll-text bold">
              {moment(poll_close_date, 'YYYY/MM/DD HH:mm').locale(lang).format('LLL')}
            </div>
          </div>

          <div className="content-font">
            <div className="generic-btn-container">
              <div className="generic-btn" onClick={this.props.actions.hidePollDialog}>
                {this.props.labels.common.cancel}
              </div>
              <div
                className="generic-btn cta-modal-btn"
                onClick={() => {
                  // start poll -> got to question page
                  this.props.actions.setPollPage('question');
                }}
              >
                {this.props.labels.poll.next}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  onRadioSelect = (evt) => {
    this.props.actions.setPollChoice(evt.target.value);
  };

  renderQuestionPage = () => {
    const {
      config: { q_id, q_type, q_text, q_choices },
      poll_topic,
      poll_id,
      lang,
      mark,
      comment,
      choice,
      multiple,
    } = this.props;

    const { displayScrlCtrl } = this.state;

    const choices =
      q_type === 'choice' || q_type === 'multiple'
        ? Object.keys(q_choices).map((key) => ({
            ...q_choices[key],
            key,
          }))
        : null;

    const setStep = () => {
      const data = {
        question_id: q_id,
        question_text: q_text['en'] ? q_text['en'] : q_text[lang],
      };
      if (q_type === 'mark') {
        data.mark = mark;
      } else if (q_type === 'choice') {
        if (!choice) return;
        data.choice = choice;
        data.choiceText = q_choices[choice]['en']
          ? q_choices[choice]['en']
          : q_choices[choice][lang];
      } else if (q_type === 'multiple') {
        if (!multiple) return;
        data.multiple = multiple;
      } else {
        data.comment = comment;
      }
      this.props.actions.setPollStep(poll_id, q_id, data);
    };

    const radioProps = choice ? { value: choice } : {};

    return (
      <>
        <DialogTitle className="title-font modal-title">{poll_topic[lang]}</DialogTitle>

        <div id="poll-dialog" className="generic-modal-content" style={getContainerStyle()}>
          <div ref="scrollableEl" className="poll-body">
            <div className="bold question_text">{q_text[lang]}</div>

            {q_type === 'mark' && (
              <Rate
                defaultValue={0}
                count={4}
                value={mark || 0}
                onChange={this.props.actions.setPollMark}
                allowHalf={false}
                allowClear
              />
            )}

            {q_type === 'choice' && (
              <RadioGroup key={q_id} onChange={this.onRadioSelect} {...radioProps}>
                {choices.map((choice) => (
                  <FormControlLabel
                    key={choice.key}
                    value={choice.key}
                    control={<Radio color="primary" />}
                    pointColor="#009a00"
                    rootColor="#AAAAAA"
                    label={choice[lang]}
                    labelPlacement="start"
                  />
                ))}
              </RadioGroup>
            )}

            {q_type === 'comment' && (
              <div className="choice-container">
                <textarea
                  className="generic-input"
                  value={comment}
                  onChange={(e) => this.props.actions.setPollComment(e.target.value)}
                  rows="3"
                  maxLength="256"
                  placeholder={this.props.labels.poll.commentPlaceholder}
                  name="comment"
                />
                <span className="textarea-count">{256 - comment.length}</span>
              </div>
            )}

            {q_type === 'multiple' && (
              <Checkboxes
                items={choices}
                values={multiple}
                lang={lang}
                q_choices={q_choices}
                onChange={(choicesArray) => this.props.actions.setPollMultiple(choicesArray)}
              />
            )}
          </div>
          <div className="content-font">
            {displayScrlCtrl && (
              <div className="scrl-ctrl">
                <div
                  className="generic-btn scroll-btn"
                  onClick={() => {
                    this.refs.scrollableEl.scrollTop = 0;
                  }}
                >
                  <GenericIcon className="arrow-up-icon" />
                </div>
                <div
                  className="generic-btn scroll-btn"
                  onClick={() => {
                    const innerHeight = this.refs.scrollableEl.scrollHeight;
                    const outterHeight = this.refs.scrollableEl.clientHeight;
                    this.refs.scrollableEl.scrollTop = innerHeight - outterHeight;
                  }}
                >
                  <GenericIcon className="arrow-down-icon" />
                </div>
              </div>
            )}

            <div className="generic-btn-container">
              {!isFirstStep(poll_id, q_id) && (
                <div
                  className="generic-btn"
                  onClick={() => {
                    setStep();
                    this.props.actions.goToPreviousPollStep(q_id);
                  }}
                >
                  {this.props.labels.poll.back}
                </div>
              )}
              <div
                className={`generic-btn cta-modal-btn ${
                  (q_type === 'choice' && !choice) || (q_type === 'mark' && !mark)
                    ? 'modal-btn-disabled'
                    : ''
                }`}
                onClick={() => {
                  if (q_type === 'choice' && !choice) return;
                  if (q_type === 'mark' && !mark) return;
                  setStep();
                  if (isLastStep(poll_id, q_id)) {
                    this.props.actions.setPollPage('submit');
                  } else {
                    this.props.actions.goToNextPollStep(q_id);
                  }
                }}
              >
                {this.props.labels.poll.next}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  renderSubmitPage = () => {
    const {
      poll_id,
      poll_topic,
      poll_close_text,
      poll_close_label,
      poll_close_link,
      lang,
      error,
      isPolling,
    } = this.props;

    if (isPolling) {
      return (
        <>
          <DialogTitle className="title-font modal-title">{poll_topic[lang]}</DialogTitle>
          <div className="generic-modal-content">
            <div className="poll-body">
              <GenericIcon layout="div" className="spinner-icon rotate-center" />
            </div>
          </div>
        </>
      );
    } else if (error === ERRORS.SUBMITTED) {
      return (
        <>
          <DialogTitle className="title-font modal-title">{poll_topic[lang]}</DialogTitle>
          <div className="generic-modal-content">
            <div className="poll-body">
              <div className="title">{this.props.labels.poll.submitted}</div>
              <div className="title">{`${this.props.labels.poll.thank}!`}</div>
            </div>

            {poll_close_text && poll_close_label && poll_close_link && (
              <div className="poll-link">
                <span>{poll_close_text[lang]} </span>
                <span
                  className="colored-link"
                  onClick={() => {
                    window.open(decodeURI(poll_close_link), '_system');
                  }}
                >
                  {poll_close_label[lang]}
                </span>
              </div>
            )}

            <div className="content-font">
              <div className="generic-btn-container">
                <div
                  className="generic-btn cta-modal-btn"
                  onClick={this.props.actions.hidePollDialog}
                >
                  {this.props.labels.common.close}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else if (error === ERRORS.NETWORK) {
      return (
        <>
          <DialogTitle className="title-font modal-title">{poll_topic[lang]}</DialogTitle>
          <div className="generic-modal-content">
            <div className="text-font error-message poll-body">
              {this.props.labels.poll.noNetwork}
            </div>
            <div className="content-font">
              <div className="generic-btn-container">
                <div
                  className="generic-btn"
                  onClick={() => {
                    this.props.actions.setPollError('null');
                    this.props.actions.setPollPage('question');
                  }}
                >
                  {this.props.labels.poll.back}
                </div>
                <div
                  className="generic-btn cta-modal-btn"
                  onClick={this.props.actions.hidePollDialog}
                >
                  {this.props.labels.common.cancel}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else if (error === ERRORS.SERVER) {
      return (
        <>
          <DialogTitle className="title-font modal-title">{poll_topic[lang]}</DialogTitle>
          <div className="generic-modal-content">
            <div className="text-font error-message poll-body">
              {this.props.labels.poll.serverError}
            </div>
            <div className="content-font">
              <div className="generic-btn-container">
                <div
                  className="generic-btn"
                  onClick={() => {
                    this.props.actions.setPollError('null');
                    this.props.actions.setPollPage('question');
                  }}
                >
                  {this.props.labels.poll.back}
                </div>
                <div
                  className="generic-btn cta-modal-btn"
                  onClick={this.props.actions.hidePollDialog}
                >
                  {this.props.labels.common.cancel}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <DialogTitle className="title-font modal-title">{poll_topic[lang]}</DialogTitle>
        <div className="generic-modal-content">
          <div className="poll-body">
            <div
              className="generic-btn cta-modal-btn submit-code-btn"
              onClick={() => {
                this.props.actions.submitPoll(poll_id);
              }}
            >
              {this.props.labels.poll.submit}
            </div>
          </div>
          <div className="content-font">
            <div className="generic-btn-container">
              <div
                className="generic-btn"
                onClick={() => this.props.actions.setPollPage('question')}
              >
                {this.props.labels.poll.back}
              </div>
              <div
                className="generic-btn cta-modal-btn"
                onClick={this.props.actions.hidePollDialog}
              >
                {this.props.labels.common.cancel}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  renderSubmittedPage = () => {
    const { poll_topic, poll_close_text, poll_close_label, poll_close_link, lang } = this.props;
    return (
      <>
        <DialogTitle className="title-font modal-title">{poll_topic[lang]}</DialogTitle>
        <div className="generic-modal-content">
          <div className="poll-body title">{this.props.labels.poll.thank}!</div>

          {poll_close_text && poll_close_label && poll_close_link && (
            <div className="poll-link">
              <span>{poll_close_text}</span>
              <span
                className="colored-link"
                onClick={() => {
                  window.open(decodeURI(poll_close_link), '_system');
                }}
              >
                {poll_close_label}
              </span>
            </div>
          )}

          <div className="content-font">
            <div className="generic-btn-container">
              <div
                className="generic-btn cta-modal-btn"
                onClick={this.props.actions.hidePollDialog}
              >
                {this.props.labels.common.close}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  render() {
    return (
      <Dialog id="poll-dialog" open={this.props.isOpen} onClose={this.props.actions.hidePollDialog}>
        {this.nav[this.props.page]()}
      </Dialog>
    );
  }
}

PollDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  labels: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  error: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => state[COMPONENT_KEY];
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(PollDialog);
