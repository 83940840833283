import React from 'react';

import { getCurrent as getCurrentLang } from 'src/core/Lang';
import { getValueFromStringAttribute } from 'src/core/util/JsTools';
import { getCodification } from 'src/core/codifications/CodificationsService';

import TextField from '../form/TextField';
import CheckBoxes from '../form/CheckBoxes';
import InputModal from '../form/InputModal';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

const LOG_PREF = '[SearchForm renderField] ';
const isCleared = {};
function getAndTransformCodification(codifParam) {
  const codification = getCodification(codifParam);

  if (!codification) {
    // console.warn(LOG_PREF+'Unable to get codification:', codifParam);
    // Probably just being fetched, skip warning
    return null;
  }

  return Object.keys(codification).map(function(codifKey) {
    let label = codification[codifKey][`label_${getCurrentLang()}`];
    if (typeof label === 'object') {
      label = label.Label;
    }

    return {
      value: codifKey,
      label: label,
    };
  });
}

export default function({
  fieldName,
  fieldConf,
  fieldsHooks,
  performSearch,
  isSearchOngoing,
  labels,
  setFieldVisiblity,
  displayLabel,
  displayIcon,
}) {
  const hook = fieldsHooks[fieldName];
  const label = fieldConf.label ? getValueFromStringAttribute(labels, fieldConf.label) : null;
  const placeholder = fieldConf.placeholder
    ? getValueFromStringAttribute(labels, fieldConf.placeholder)
    : null;
  const codifications = fieldConf.values && getAndTransformCodification(fieldConf.values.codif);

  const clearField = (value) => {
    hook.setValue(value);
    isCleared[fieldName] = true;
  };

  /*
   ******************************
   * render Input for Search Form
   ******************************
   */
  const renderInput = ({
    name,
    label,
    className,
    type,
    value,
    onBlur,
    readOnly,
    disabled,
    required,
    onClick,
    onChange,
  }) => {
    if (typeof setFieldVisiblity === 'function') {
      setFieldVisiblity(name, true);
    }
    let valueLabel = value;
    // create element result from checkbox
    if (type === 'checkbox-modal') {
      valueLabel = fieldConf.multiple
        ? value &&
          value.map((codif) => {
            return (
              <span className="item-checkbox" key={codif}>
                {codifications.find((code) => code.value === codif).label}
              </span>
            );
          })
        : codifications &&
          value &&
          codifications.find((code) => code.value === value) &&
          codifications.find((code) => code.value === value).label;
    }
    return (
      <div className="generic-field-row">
        <div className="input-search input-with-label hasClick">
          {label && <label className="text-color1 input-label">{label}</label>}
          <div
            onClick={onClick}
            name={name}
            className={className}
            placeholder={placeholder}
            type={type}
            onBlur={onBlur}
            readOnly={readOnly}
            disabled={disabled}
            required={required}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          >
            <div className="result">
              {valueLabel && valueLabel.length > 0 ? (
                <span className="result-value">{valueLabel}</span>
              ) : (
                <span className={'result-placeholder'}>{placeholder}</span>
              )}
            </div>
          </div>
          {valueLabel && valueLabel.length > 0 && (
            <GenericIcon
              className="search-field-clear times-icon"
              src="icons/control/times-solid.svg"
              onClick={() => (type === 'checkbox-modal' ? clearField([]) : clearField(''))}
            />
          )}
        </div>
      </div>
    );
  };

  /**
   * ********************
   * End renderInput
   * ********************
   */
  /*
   ******************************
   * render Input on Modal
   *****************************
   */
  const renderInputOnModal = ({
    name,
    label,
    className,
    placeholder,
    type,
    value,
    onBlur,
    readOnly,
    disabled,
    required,
    onClick,
    onChange,
  }) => {
    switch (type) {
      case 'text-modal':
      case 'text': // EDIT_TEXT
      case 'number': // NUMERIC
      case 'date': // DATE_TIME
      case 'email': // EMAIL
      case 'textarea': // TEXT_AREA
        return (
          <TextField
            fieldName={name}
            type={type}
            className={className}
            currentFormValue={value || ''}
            setValue={onChange}
            label={placeholder}
            autofocus
          />
        );

      case 'radio': // SINGLE_CHOICE
      case 'checkbox': // MULTI_CHOICE
      case 'checkbox-modal': // MULTI_CHOICE
        return (
          <CheckBoxes
            fieldName={name}
            className={className}
            {...hook}
            currentFormValue={value || []}
            setValue={onChange}
            labels={labels}
            multiple={fieldConf.multiple}
            values={fieldConf.values.codif ? codifications : fieldConf.label}
          />
        );

      case 'switch': // BOOLEAN - Not displayed using InputModal
        return null;

      default:
        console.error('Unexpected field type: ' + type);
        return null;
    }
  };

  /**
   * ********************
   * End Input on Modal
   * ********************
   */

  switch (fieldConf.type) {
    case 'text':
      return TextField({
        fieldName,
        ...hook,
        label,
        placeholder,
        performSearch,
        isSearchOngoing,
        hasSearchButton: !!fieldConf.hasSearchButton,
        setFieldVisiblity,
        icon: displayIcon && fieldConf.icon,
        displayLabel,
      });

    case 'checkbox':
      return CheckBoxes({
        fieldName,
        ...hook,
        label,
        placeholder,
        labels,
        icon: displayIcon && fieldConf.icon,
        multiple: fieldConf.multiple,
        values: fieldConf.values.codif ? codifications : fieldConf.values,
        setFieldVisiblity,
        displayLabel,
      });
    case 'checkbox-modal':
    case 'text-modal':
      return (
        <InputModal
          key={fieldName}
          name={fieldName}
          type={fieldConf.type}
          label={label}
          isCleared={isCleared}
          setCleared={() => {
            isCleared[fieldName] = false;
          }}
          initialValue={hook.currentFormValue || ''}
          displayLabel
          readOnly={false}
          required={false}
          disabled={false} // !field.editable
          placeHolder={placeholder}
          fieldClassName={''}
          renderClickableInput={renderInput}
          renderModalInput={renderInputOnModal}
          hideOnSubmit
          submit={hook.setValue}
          requestStatus={{ saving: false }}
          labels={labels}
          maxHeight={500}
        />
      );

    default:
      console.error(`${LOG_PREF}TODO: render field type '${fieldConf.type}'`);
  }
}
