/**
 *
 * Configuration describing how 2 pages are displayed simultaneously, screen being vertically split
 *
 */
import config from 'data/config/config';
import // AIRCRAFT_PAGE_KEY,
// EVENT_PAGE_KEY,
// EXHIBITOR_PAGE_KEY,
// LIST_PAGE_KEY,
// HOME_PAGE_KEY,
// MOBIGEO_PAGE_KEY,
// NEWPRODUCT_PAGE_KEY,
// RESTAURANT_PAGE_KEY,
// SERVICE_PAGE_KEY,
// EXPERIENCE_ITEM_PAGE_KEY,
// DOCUNIT_PAGE_KEY
'src/pages/pagesKeys.js';
import // DATA_TYPE_ANIMATIONS,
// DATA_TYPE_DOCUNITS,
// DATA_TYPE_EVENT_CATEGORIES,
// DATA_TYPE_EXHIBITOR_CATEGORIES,
// DATA_TYPE_AIRCRAFT_CATEGORIES,
// DATA_TYPE_NEWPRODUCT_CATEGORIES
'data/config/dataConfig';
export var FEATURE_ACTIVATED = false;
/**
 * Required min width to auto-enable this mode
 * @type {Number}
 */

export var MIN_WIDTH = 800;
export var AREA = {
  right: 'right',
  left: 'left'
};
/**
 * Default area for all pages
 */

export var TWO_COLUMNS_DEFAULT = AREA.left;
/**
 * Every page NOT declared here will rely on `TWO_COLUMNS_DEFAULT`
 */

export var PAGES_SETUP = {// [MOBIGEO_PAGE_KEY]: AREA.right,
  // + fiches (exh, event, service, etc) ?
};
/**
 * Indicate in which columns a page should be displayed
 * @param  {string} pageKey
 * @return {string}
 * @see    pagesTwoColumnsConfig.AREA
 */

export var getArea = function getArea(pageKey) {
  return PAGES_SETUP[pageKey] || TWO_COLUMNS_DEFAULT;
};
/**
 * Toolbar is displayed on a single area. Define here on which one
 */

export var TOOLBAR_AREA = AREA.left;
/**
 * CONFIGURE THIS FOR EVERY PROJECT
 */

export function getSecondPage(displayedPageKey, displayedPageProps, profile) {
  var displayedPageArea = getArea(displayedPageKey); // If displayed page is on left side, then display Map on the right side

  if (displayedPageArea === AREA.left) {
    return {
      pageKey: config.MAP.DEFAULT_MOBIGEO_PAGE_KEY
    };
  }

  switch (displayedPageKey) {
    case EVENT_PAGE_KEY:
      return {
        pageKey: LIST_PAGE_KEY,
        props: {
          inputs: [{
            dataType: DATA_TYPE_EVENT_CATEGORIES
          }]
        }
      };

    case EXHIBITOR_PAGE_KEY:
      return {
        pageKey: LIST_PAGE_KEY,
        props: {
          inputs: [{
            dataType: DATA_TYPE_EXHIBITOR_CATEGORIES
          }]
        }
      };
    // case AIRCRAFT_PAGE_KEY:
    //     return {
    //         pageKey: LIST_PAGE_KEY,
    //         props  : { inputs: [{ dataType: DATA_TYPE_AIRCRAFT_CATEGORIES }]},
    //     };
    // case NEWPRODUCT_PAGE_KEY:
    //     return {
    //         pageKey: LIST_PAGE_KEY,
    //         props  : { inputs: [{ dataType: DATA_TYPE_NEWPRODUCT_CATEGORIES }]},
    //     };
    // case EXPERIENCE_ITEM_PAGE_KEY:
    //     return {
    //         pageKey: LIST_PAGE_KEY,
    //         props  : { inputs: [{ dataType: DATA_TYPE_ANIMATIONS }]},
    //     };
    // case DOCUNIT_PAGE_KEY:
    //     return {
    //         pageKey: LIST_PAGE_KEY,
    //         props  : { inputs: [{ dataType: DATA_TYPE_DOCUNITS }]},
    //     }
    // case PAPER_PAGE_KEY:
    //     return {
    //         pageKey: LIST_PAGE_KEY,
    //         props  : { inputs: [{ dataType: DATA_TYPE_PAPER_CATEGORIES }]},
    //     };
    // case SERVICE_PAGE_KEY:
    //     return {
    //         pageKey: LIST_PAGE_KEY,
    //         props  : { inputs: [{ dataType: DATA_TYPE_SERVICE_CATEGORIES }]},
    //     };
    // case MOBIGEO_PAGE_KEY:
    //     return config.getHomePage(profile);
  }

  return {
    pageKey: LIST_PAGE_KEY,
    props: {
      inputs: [{
        dataType: DATA_TYPE_EXHIBITORS
      }]
    }
  };
}
/**
 * Declare fiche page keys for which POI should be automatically displayed on map
 * @type {Array}
 */

export var AUTOSHOW_POI_ON_MAP = {//    [AIRCRAFT_PAGE_KEY]  : true,
  // [EVENT_PAGE_KEY]     : true,
  // [EXHIBITOR_PAGE_KEY] : true,
  // // [RESTAURANT_PAGE_KEY]: true,
  // [SERVICE_PAGE_KEY]   : true,
};