import React from 'react';
import PropTypes from 'prop-types';

import config from 'data/config/config';

import isOnline from 'src/core/util/isOnline';
import showConfirmModal from 'src/core/util/showConfirmModal';
import { getBindedActions } from 'src/store/bindedActions';
import { WS_ERRORS } from 'src/core/webservices/WS_ERRORS';
import { get as getLabels } from 'src/core/Lang';
import showGenericWsErrorNotification from 'src/core/webservices/showGenericWsErrorNotification';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';
import { formatDayLong } from 'src/core/Lang';
import STATUS from 'src/store/fetchStatuses';
import Loader from 'src/components/loader/Loader';

import {
  contactParticipant,
  isSameUser,
  hasParticipantBeenContacted,
} from 'src/core/participants/ParticipantsService';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

import './ContactButton.scss';

const LOG_PREF = '[ContactButton] ';

class ContactButton extends React.Component {
  state = {
    disabled: false,
  };

  componentDidMount() {
    let label = hasParticipantBeenContacted(this.props.participant)
      ? this.props.labels.common.contacted
      : this.props.labels.appointment.button;
    this.setState({ label });
  }

  contact = (e) => {
    e.stopPropagation();
    let btnEl = e.target;
    if (btnEl.attributes.disabled) {
      return;
    }

    let participantId = btnEl.dataset.participantId;
    if (!participantId) {
      console.error(LOG_PREF + 'Missing participant id in dataset');
      return;
    }

    let self = this;

    isOnline((hasNetwork) => {
      let labels = getLabels();

      if (!hasNetwork) {
        getBindedActions().showNotification({
          message: labels.common.noNetworkTitle,
        });
      } else {
        showConfirmModal({
          title: labels.common.contact,
          text: labels.common.contactConfirmMessage,
          yesBtnLabel: labels.common.ok,
          noBtnLabel: labels.common.cancel,
          yesCb: function () {
            self.setState({ disabled: true });

            contactParticipant(participantId, function (error) {
              if (error) {
                if (error === WS_ERRORS.NETWORK) {
                  showGenericWsErrorNotification(error, NotificationLevels.ERROR);
                } else {
                  getBindedActions().showNotification({
                    title: labels.common.contactErrorTitle,
                    message: labels.common.contactError,
                    level: NotificationLevels.ERROR,
                  });
                }
                // Re-enable button
                self.setState({ disabled: false, label: self.props.labels.appointment.button });
              }
              // in case of success, keep button disabled. data is being refreshed
            });
            self.setState({ label: self.props.labels.common.contacted });
          },
          noCb: () => {},
        });
      }
    });
  };

  render() {
    if (!config.NETWORKING || !config.NETWORKING.FEATURE_ENABLED) {
      return null;
    }

    let { participant } = this.props;

    // No participant data
    if (!participant) {
      return null;
    }

    // Participant data is being fetched
    if (participant.status === STATUS.PENDING) {
      return <Loader className="contact-btn-loader" labels={this.props.labels} />;
    }

    // Item is not contactable
    if (!participant.contactable || isSameUser(participant)) {
      return null;
    }

    // Tests passed, show the button

    let hasBeenContacted = hasParticipantBeenContacted(participant);

    let label, disabled, onClick;
    if (hasBeenContacted) {
      // Already contacted
      disabled = true;
      onClick = null;
      // label = this.props.labels.common.contacted;
    } else {
      // Not contacted
      disabled = this.state.disabled;
      onClick = this.contact;
      // label = this.props.labels.appointment.button;
    }

    return (
      <div
        className="contact-btn"
        data-participant-id={participant.id}
        disabled={disabled}
        onClick={onClick}
      >
        <GenericIcon
          layout="div"
          src="icons/homeToolBar/envelope-solid.svg"
          className={"contact-btn-icon envelope-icon "}
        />
        {this.state.label && <span className="contact-btn-text">{this.state.label}</span>}
      </div>
    );
  }
}

ContactButton.propTypes = {
  participant: PropTypes.object,
  labels: PropTypes.object.isRequired,
};

export default ContactButton;
